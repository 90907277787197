// tslint:disable
// this is an auto generated file. This will be overwritten

export const createVimeoId = `mutation CreateVimeoId($input: CreateVimeoIdInput!) {
  createVimeoId(input: $input) {
    id
    videoName
    vimeoId
  }
}
`;
export const updateVimeoId = `mutation UpdateVimeoId($input: UpdateVimeoIdInput!) {
  updateVimeoId(input: $input) {
    id
    videoName
    vimeoId
  }
}
`;
export const deleteVimeoId = `mutation DeleteVimeoId($input: DeleteVimeoIdInput!) {
  deleteVimeoId(input: $input) {
    id
    videoName
    vimeoId
  }
}
`;
export const createProgress = `mutation CreateProgress($input: CreateProgressInput!) {
  createProgress(input: $input) {
    id
    moduleId
    videoOrder
    videoName
    slug
    owner
  }
}
`;
export const updateProgress = `mutation UpdateProgress($input: UpdateProgressInput!) {
  updateProgress(input: $input) {
    id
    moduleId
    videoOrder
    videoName
    slug
    owner
  }
}
`;
export const deleteProgress = `mutation DeleteProgress($input: DeleteProgressInput!) {
  deleteProgress(input: $input) {
    id
    moduleId
    videoOrder
    videoName
    slug
    owner
  }
}
`;
