import * as React from "react"
import WithRoot from "./withRoot"
import { userIsInGroup } from "../utils/modelService"
import { navigate } from "gatsby"
import config from "../config"
import { Button } from "@material-ui/core"
import { API, graphqlOperation, Auth } from "aws-amplify"
import { createVimeoId, deleteVimeoId } from "../graphql/mutations"
import { listVimeoIds } from "../graphql/queries"
import ReactDataGrid from "react-data-grid"

const AdminPage = () => {
  const [masterCourseData, setMasterCourseData] = React.useState<VideoMaster[]>()
  const [time, setTime] = React.useState(0)
  const [masterCourseColumns, setMasterCourseColumns] = React.useState()
  const [masterRowData, setMasterRowData] = React.useState()

  React.useEffect(() => {
    init()
  }, [])
  const baseUrl = process.env.ADMIN_SERVICE

  const init = async () => {
    const isManager = await userIsInGroup("Managers")
    if (!isManager) navigate("/")
    await refreshData()
  }

  const runPrivacy = async () => {
    const updatePayload: { vimeoId: string; lookUpKey: string }[] = []
    const allEntries = await Promise.all(
      masterCourseData.map(async module => {
        return await Promise.all(
          module.videos.map(video => {
            return new Promise(async (res, rej) => {
              try {
                if (!video.vimioId || (!!video.vimioId && !!video.vimeoLookUp)) {
                  res()
                  return
                }

                const existingApSyncRecord = await API.graphql(
                  graphqlOperation(listVimeoIds, {
                    filter: {
                      vimeoId: { eq: video.vimioId }
                    }
                  })
                )
                if (existingApSyncRecord.data.listVimeoIds.items.length !== 0) {
                  res({
                    vimeoId: existingApSyncRecord.data.listVimeoIds.items[0].vimeoId,
                    lookUpKey: existingApSyncRecord.data.listVimeoIds.items[0].id
                  })
                  return
                }

                const apSyncRecord = await API.graphql(
                  graphqlOperation(createVimeoId, {
                    input: {
                      videoName: video.title,
                      vimeoId: video.vimioId
                    }
                  })
                )

                const entry = {
                  vimeoId: apSyncRecord.data.createVimeoId.vimeoId,
                  lookUpKey: apSyncRecord.data.createVimeoId.id
                }
                res(entry)
              } catch (err) {
                console.error(err)
                rej(err)
              }
            })
          })
        )
      })
    )
    const keyVals = allEntries.flat().filter(entry => !!entry)
    await fetch(`${baseUrl}/update-vimeo-key`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(keyVals)
    })
  }

  const cleanAllEntries = async () => {
    await refreshData()
    const apSyncRecord = await API.graphql(graphqlOperation(listVimeoIds))

    await Promise.all(
      apSyncRecord.data.listVimeoIds.items.map(item => {
        return API.graphql(
          graphqlOperation(deleteVimeoId, {
            input: {
              id: item.id
            }
          })
        )
      })
    )

    console.log("deleted all")
  }

  async function refreshData() {
    const response = await fetch(`${baseUrl}`)
    const courseDataResponse = await response.json()
    setMasterCourseData(courseDataResponse)

    const totalTime = courseDataResponse.reduce((prev, curr) => {
      const modTotal = curr.videos.reduce((count, currVid) => {
        return count + currVid.duration
      }, 0)
      return prev + modTotal
    }, 0)
    setTime(totalTime)
    return courseDataResponse
  }

  const getTime = time => {
    var minutes = Math.floor(time / 60)
    var seconds = time - minutes * 60
    return {
      minutes,
      seconds
    }
  }

  const runPropagationScript = async () => {
    await refreshData()
    await runPrivacy()

    await fetch(`${baseUrl}/run-propagation`, {
      method: "POST"
    })
    const response = await fetch(`${baseUrl}`)
    await refreshData()
    alert("done")
  }

  const onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    // const rows = state.rows.slice()
    // for (let i = fromRow; i <= toRow; i++) {
    //   rows[i] = { ...rows[i], ...updated }
    // }
  }

  return (
    <div>
      {/* <Button color="primary" onClick={() => cleanAllEntries()}>
        remove entries in private database
      </Button> */}

      <Button color="secondary" onClick={() => runPropagationScript()}>
        run propagation script
      </Button>

      <br />

      <div>
        Total min = {getTime(time).minutes} : {getTime(time).seconds}{" "}
      </div>

      {/* <ReactDataGrid
        columns={masterCourseColumns}
        rowGetter={i => masterCourseData[i]}
        rowsCount={3}
        onGridRowsUpdated={onGridRowsUpdated}
        enableCellSelect={true}
      /> */}
      <pre>{JSON.stringify(masterCourseData, null, 4)}</pre>
    </div>
  )
}

export default WithRoot(AdminPage)
